import React from "react";
import { Grid } from "@mui/material";
import MyDevelop from "../img/client.jpg";
import CommonFooter from "./CommonFooter";

const Clients = () => {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#4e5d78",
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: "10em",
          paddingBottom: "5em",
        }}
      >
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "3.25em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Less Firefighting, More Innovating
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.3em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              Technical leadership in fast-paced companies can be stressful.
              Deadlines and bugs can build quickly. We help our clients create a
              more predictable delivery cycle that gives you the confidence to
              innovate and move fast.
            </p>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img
              style={{ width: "100%", borderRadius: 10 }}
              src={MyDevelop}
              alt="my develop"
            />
          </Grid>
        </Grid>
      </div>
      <CommonFooter />
    </div>
  );
};

export default Clients;
