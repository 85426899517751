import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import "../styles/TopAppBar.css";
import Logo from "../img/sailing.png";
import Hidden from "@mui/material/Hidden";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";

export default function ButtonAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style={{ backgroundColor: "#2979ff" }} position="fixed">
        <Toolbar className="header_content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <Link style={{ textDecoration: "none", color: "white" }} to="/">
                <img
                  className="logo_animation bounce-1"
                  src={Logo}
                  height={40}
                  alt="Logo"
                  width={50}
                />
              </Link>
            </IconButton>
            <div
              style={{
                textTransform: "none",
                fontSize: "1.5em",
                fontFamily: "Work Sans",
                lineHeight: 1.2,
                textDecoration: "none",
                letterSpacing: "2px",
                fontWeight: 400,
              }}
              color="inherit"
            >
              <Link style={{ textDecoration: "none", color: "white" }} to="/">
                Kismiriya
              </Link>
            </div>
          </div>
          <Hidden only={["md", "lg", "xl"]}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              id="demo-positioned-button"
              aria-controls="demo-positioned-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link style={{ textDecoration: "none" }} to="/about">
                  About
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link style={{ textDecoration: "none" }} to="/clients">
                  Clients
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link style={{ textDecoration: "none" }} to="/services">
                  Services
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link style={{ textDecoration: "none" }} to="/blog">
                  Blog
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link style={{ textDecoration: "none" }} to="/ourteam">
                  Team
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link style={{ textDecoration: "none" }} to="/talktous">
                  ContactUs
                </Link>
              </MenuItem>
            </Menu>
          </Hidden>
          <Hidden only={["sm", "xs"]}>
            {/* <Paper className={classes.paper}>Hidden on sm</Paper> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "1.2em",
                  fontFamily: "Work Sans",
                  lineHeight: 1.2,
                  textDecoration: "none",
                  letterSpacing: "2px",
                  fontWeight: 400,
                  "&:hover": {
                    background: "#f00",
                  },
                }}
                color="inherit"
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/about"
                >
                  About
                </Link>
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "1.2em",
                  fontFamily: "Work Sans",
                  lineHeight: 1.2,
                  textDecoration: "none",
                  letterSpacing: "2px",
                  fontWeight: 400,
                }}
                color="inherit"
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/clients"
                >
                  Clients
                </Link>
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "1.2em",
                  fontFamily: "Work Sans",
                  lineHeight: 1.2,
                  textDecoration: "none",
                  letterSpacing: "2px",
                  fontWeight: 400,
                }}
                color="inherit"
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/services"
                >
                  Services
                </Link>
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "1.2em",
                  fontFamily: "Work Sans",
                  lineHeight: 1.2,
                  textDecoration: "none",
                  letterSpacing: "2px",
                  fontWeight: 400,
                }}
                color="inherit"
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/blog"
                >
                  Blog
                </Link>
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "1.2em",
                  fontFamily: "Work Sans",
                  lineHeight: 1.2,
                  textDecoration: "none",
                  letterSpacing: "2px",
                  fontWeight: 400,
                }}
                color="inherit"
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/ourteam"
                >
                  Team
                </Link>
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "1.2em",
                  fontFamily: "Work Sans",
                  lineHeight: 1.2,
                  textDecoration: "none",
                  letterSpacing: "2px",
                  fontWeight: 400,
                }}
                color="inherit"
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/talktous"
                >
                  ContactUs
                </Link>
              </Button>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
