import React from "react";
import MyBlog from "../img/blog.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import CommonFooter from "./CommonFooter";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Anjani from "../img/kumar1.jpeg";
import Boon from "../img/boon2.jpeg";
import Soni from "../img/soni.jpeg";
import Pushpa from "../img/pushpa.jpeg";
import Rohit from "../img/rohit.jpeg";
import Sumit from "../img/sumit.jpeg";

const Blog = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${MyBlog})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: 100,
          paddingTop: 100,
          height: "100vh",
          width: "100vw",
        }}
      >
        <h1
          style={{
            fontFamily: "DM Sans",
            color: "#000000",
            fontSize: "3em",
            lineHeight: "1.15em",
            fontWeight: 500,
          }}
        >
          Blog
        </h1>
      </div>
      <div
        style={{
          backgroundColor: "#009688",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
          paddingTop: "3em",
          paddingBottom: "3em",
        }}
      >
        <Card sx={{ maxWidth: 345, marginTop: "3em" }}>
          <CardActionArea>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                How to Hire a Software Engineer (if you’re not technical)
              </Typography>
              <Typography
                style={{ textAlign: "justify" }}
                variant="body2"
                color="text.secondary"
              >
                You're an entrepreneur who wants to develop an amazing digital
                product. You’ve got the killer idea, the marketing team, the
                business model, and yet there’s one thing that’s proving harder
                than usual: hiring the software developers.
              </Typography>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "2em",
                }}
                direction="row"
                spacing={2}
              >
                <Avatar
                  alt="Anjani"
                  src={Boon}
                  sx={{ width: 56, height: 56 }}
                />
                <h3>Denish Boon</h3>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 345, marginTop: "3em" }}>
          <CardActionArea>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                The Daily Standup is Dead. Why You Need it More Than Ever
              </Typography>
              <Typography
                style={{ textAlign: "justify" }}
                variant="body2"
                color="text.secondary"
              >
                Daily standups have been somewhat of a ritual for startups and
                software teams for the past 20 years. But recently people have
                come to despise them, thinking they are a big waste of time.
                What’s the purpose of these meetings, especially now that we’re
                all remote?
              </Typography>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "1em",
                }}
                direction="row"
                spacing={2}
              >
                <Avatar
                  alt="Anjani"
                  src={Anjani}
                  sx={{ width: 56, height: 56 }}
                />
                <h3>Anjani Kumar</h3>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 345, marginTop: "3em" }}>
          <CardActionArea>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                More Diversity, Better Performance
              </Typography>
              <Typography
                style={{ textAlign: "justify" }}
                variant="body2"
                color="text.secondary"
              >
                Diversifying your teams through gender, race, cultural
                background, and sexual orientation leads to more innovation,
                revenue, and adaptability to change. But creating diverse teams
                doesn’t just happen on its own, you need to create dedicated
                initiatives and build a plan around team diversity.
              </Typography>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "3em",
                }}
                direction="row"
                spacing={2}
              >
                <Avatar
                  alt="Anjani"
                  src={Rohit}
                  sx={{ width: 56, height: 56 }}
                />
                <h3>Rohit King</h3>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 345, marginTop: "3em" }}>
          <CardActionArea>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                Tech Showdown: Angular vs React
              </Typography>
              <Typography
                style={{ textAlign: "justify" }}
                variant="body2"
                color="text.secondary"
              >
                Two of our experienced frontend developers debate the pros and
                cons of Angular vs React. Watch now to learn the best
                application for each as Giancarlo and Josue debate their sides.
              </Typography>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "6em",
                }}
                direction="row"
                spacing={2}
              >
                <Avatar
                  alt="Anjani"
                  src={Sumit}
                  sx={{ width: 56, height: 56 }}
                />
                <h3>Sumit Kumar Roy</h3>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 345, marginTop: "3em" }}>
          <CardActionArea>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                How to tell if a developer is “good” if you’re not technical
              </Typography>
              <Typography
                style={{ textAlign: "justify" }}
                variant="body2"
                color="text.secondary"
              >
                Learn how to evaluate a software developer candidate even though
                you might not have experience or exposure to software
                engineering.
              </Typography>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "7.5em",
                }}
                direction="row"
                spacing={2}
              >
                <Avatar
                  alt="Anjani"
                  src={Pushpa}
                  sx={{ width: 56, height: 56 }}
                />
                <h3>Pushpa Kumari</h3>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 345, marginTop: "3em" }}>
          <CardActionArea>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                Looking for Software Development Engineer in Test?
              </Typography>
              <Typography
                style={{ textAlign: "justify" }}
                variant="body2"
                color="text.secondary"
              >
                Learn more about the benefits of hiring a software developer
                engineer in test to increase test coverage across tech stacks.
              </Typography>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "5em",
                }}
                direction="row"
                spacing={2}
              >
                <Avatar
                  alt="Anjani"
                  src={Soni}
                  sx={{ width: 56, height: 56 }}
                />
                <h3>Soni Kumari</h3>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
      <CommonFooter />
    </div>
  );
};

export default Blog;
