import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Grid } from "@mui/material";
import Anjani from "../img/anjani.jpg";
import CommonFooter from "./CommonFooter";
import Sumit from "../img/sumitk.jpeg";
import Boon from "../img/boon2.jpeg";
import Amar from "../img/amar.jpeg";
import Nidhi from "../img/nidhi1.jpeg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const images = [
  {
    label: "Sumit Kumar Roy, Bengaluru",
    imgPath: Sumit,
  },
  {
    label: "Anjani Kumar, Patna",
    imgPath: Anjani,
  },

  {
    label: "Amar Singh, Bengaluru",
    imgPath: Amar,
  },
  {
    label: "Nidhi Singh, Delhi",
    imgPath: Nidhi,
  },
  {
    label: "Denish Boon, New Delhi",
    imgPath: Boon,
  },
];
const OurTeam = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <div style={{ backgroundColor: "#4e5d78" }}>
      <Grid
        container
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: "5em",
          paddingBottom: "5em",
        }}
      >
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
            <Paper
              style={{ backgroundColor: "#4e5d78", borderRadius: 10 }}
              square
              elevation={0}
              sx={{
                display: "flex",
                alignItems: "center",
                height: 50,
                pl: 2,
                bgcolor: "background.default",
              }}
            >
              <Typography style={{ color: "#fff" }}>
                {images[activeStep].label}
              </Typography>
            </Paper>
            <AutoPlaySwipeableViews
              style={{ borderRadius: 10 }}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      style={{ height: "30em" }}
                      component="img"
                      sx={{
                        height: "100%",
                        display: "block",
                        maxWidth: "100%",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={{ paddingTop: 20 }}
        >
          <h2
            style={{
              fontFamily: "DM Sans",
              color: "#fafbfb",
              fontSize: "2.25em",
              lineHeight: "1.15em",
              fontWeight: 500,
              letterSpacing: "-1px",
            }}
          >
            It's time to create the Perfect Team
          </h2>
          <h2
            style={{
              fontFamily: "DM Sans",
              color: "#e1e4e8",
              fontSize: "1.3em",
              lineHeight: "1.5em",
              fontWeight: 400,
              letterSpacing: "0px",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            We offer a variety of roles, skills, and seniority to choose from so
            you can build the perfect team that will adapt to your business
            seamlessly.
          </h2>
          <p
            style={{
              fontFamily: "DM Sans",
              color: "#e1e4e8",
              fontSize: "1.3em",
              lineHeight: "1.5em",
              fontWeight: 400,
              letterSpacing: "0px",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            I’ve developed a full-stack product suite to automate DevOps. I've
            helped an enterprise media company migrate to the cloud using
            Landing Zones.
          </p>
        </Grid>
      </Grid>
      <div style={{ backgroundColor: "#009688", padding: 30 }}>
        <div>
          <h1
            style={{
              fontFamily: "DM Sans",
              color: "#fafbfb",
              fontSize: "2.30em",
              lineHeight: "1.15em",
              fontWeight: 500,
              letterSpacing: "-1px",
            }}
          >
            My Team Work
          </h1>
        </div>
        <Grid container spacing={2} style={{ marginTop: 50 }}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Software Development
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.1em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              We specialize in solving tough technical challenges with high
              quality, defect-free code that we’re always proud of pushing. We
              code in a wide variety of languages and frameworks using modern,
              agile development practices.
            </p>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Project Management
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.1em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              Every project has a team lead who is responsible for ensuring
              requirements are met and timelines made. We work closely with your
              PMs and engineering managers to set the right deliverable
              expectations.
            </p>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Architecture
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.1em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              We understand that when starting new projects, you can’t just
              start writing code. We use our expertise and experience to plan
              and architect a robust solution that will scale over time.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 50 }}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              QA & Testing
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.1em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              Using modern QA and testing tools to identify, document, and
              manage bugs, we’re able to ensure we deliver high quality code
              that improves over time.
            </p>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Integrations & Data Migrations
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.1em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              We have integration and data management experience with common
              tools such as Salesforce to 3rd party custom applications. We also
              specialize in legacy to modern application upgrades.
            </p>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Support & Maintenance
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.1em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              Like a car, software needs to be maintained to keep running
              smoothly. We can help modernize legacy code or add the necessary
              support to keep it functioning optimally.
            </p>
          </Grid>
        </Grid>
      </div>
      <CommonFooter />
    </div>
  );
};

export default OurTeam;
