import React from "react";
import { Grid } from "@mui/material";
import MyDesk from "../img/desk.jpg";
import MyApp from "../img/app.jpg";
import MyDev from "../img/dev.jpg";
import CustomGirl from "../img/custom_girl.jpg";
import CommonFooter from "./CommonFooter";
const Services = () => {
  return (
    <div style={{ backgroundColor: "#4e5d78" }}>
      <div
        style={{
          backgroundColor: "#4e5d78",
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: "10em",
          paddingBottom: "5em",
        }}
      >
        <Grid container style={{ marginTop: 50 }}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img
              style={{ width: "100%", borderRadius: 10 }}
              src={MyDesk}
              alt="my desk"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2.25em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Web Development
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.3em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              We focus on web designs that are functionality fast and seamlessly
              interactive for the critical audience of today and tomorrow. Our
              web development service focuses on building responsive interfaces
              to tame the increasing bounce rate of users. Our developers help
              businesses seize the digital footprint with optimized
              user-interaction and user-engagement
            </p>
          </Grid>
        </Grid>
      </div>
      <div style={{ backgroundColor: "#009688", padding: 30 }}>
        <Grid container style={{ marginTop: 50 }}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2.25em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Mobile App Development
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.3em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              Our app designs are functional and critical. We follow a carefully
              designed app development strategy to safely integrate adaptable
              back-ends, aesthetic layouts, code-rich, and responsive features
              in our app designs
            </p>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img
              style={{ width: "100%", borderRadius: 10 }}
              src={MyApp}
              alt="my app"
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ backgroundColor: "#4e5d78", padding: 30 }}>
        <Grid container style={{ marginTop: 50 }}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img
              style={{ width: "100%", borderRadius: 10 }}
              src={MyDev}
              alt="my dev"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2.25em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Dev and Ops
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.3em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              DevOps services enhanced software processes, optimized toolchains,
              and overall software development support. We pre-plan an agile
              development approach and develop software solutions that offer
              continuous digital success. You can aim for scalability and
              reliability in your software performance and continuation.
            </p>
          </Grid>
        </Grid>
      </div>
      <div style={{ backgroundColor: "#009688", padding: 30 }}>
        <Grid container style={{ marginTop: 50 }}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "2.25em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Custom Software Development
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.3em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              We specialize in solving tough technical challenges with high
              quality, defect-free code that we’re always proud of pushing. We
              code in a wide variety of languages and frameworks using modern,
              agile development practices.
            </p>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img
              style={{ width: "100%", borderRadius: 10 }}
              src={CustomGirl}
              alt="custom girl"
            />
          </Grid>
        </Grid>
      </div>
      <CommonFooter />
    </div>
  );
};

export default Services;
