import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

const CommonFooter = () => {
  return (
    <div>
      <div style={{ marginTop: 5, backgroundColor: "#14284b" }}>
        <Grid container spacing={1} style={{ padding: 5 }}>
          <Grid
            container
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <h2 style={{ color: "#04d2c8", fontFamily: "Work Sans" }}>
              KISMIRIYA
            </h2>
            <p
              style={{
                color: "#ffffff",
                fontSize: 18,
                fontFamily: "bold",
                textAlign: "center",
              }}
            >
              Kismiriya is a software development company specializing in
              providing with custom technology solutions. As a mobile and
              web-based application development company with over skilled
              software developers,
            </p>
          </Grid>
          <Grid
            container
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                color: "#04d2c8",
                fontFamily: "Work Sans",
                textAlign: "center",
              }}
            >
              PRODUCTS
            </h2>
            <div style={{ lineHeight: "0.3em" }}>
              <p style={{ color: "#ffffff", fontSize: 18, fontFamily: "bold" }}>
                HTML5
              </p>
              <p style={{ color: "#ffffff", fontSize: 18, fontFamily: "bold" }}>
                CSS3
              </p>
              <p style={{ color: "#ffffff", fontSize: 18, fontFamily: "bold" }}>
                Bootstrap5
              </p>
              <p style={{ color: "#ffffff", fontSize: 18, fontFamily: "bold" }}>
                React Js
              </p>
              <p style={{ color: "#ffffff", fontSize: 18, fontFamily: "bold" }}>
                Angular
              </p>
              <p style={{ color: "#ffffff", fontSize: 18, fontFamily: "bold" }}>
                React Native
              </p>
              <p style={{ color: "#ffffff", fontSize: 18, fontFamily: "bold" }}>
                JavaScript
              </p>

              <p style={{ color: "#ffffff", fontSize: 18, fontFamily: "bold" }}>
                Python
              </p>
              <p style={{ color: "#ffffff", fontSize: 18, fontFamily: "bold" }}>
                MangoDb
              </p>
            </div>
          </Grid>
          <Grid
            container
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <h2 style={{ color: "#04d2c8", fontFamily: "Work Sans" }}>
              USEFUL LINK
            </h2>
            <Link
              style={{
                textDecoration: "none",
                color: "#ffffff",
                fontSize: 18,
                fontFamily: "bold",
              }}
              to="/"
            >
              Home
            </Link>
            <Link
              style={{
                textDecoration: "none",
                color: "#ffffff",
                fontSize: 18,
                fontFamily: "bold",
              }}
              to="/about"
            >
              About
            </Link>
            <Link
              style={{
                textDecoration: "none",
                color: "#ffffff",
                fontSize: 18,
                fontFamily: "bold",
              }}
              to="/clients"
            >
              Clients
            </Link>
            <Link
              style={{
                textDecoration: "none",
                color: "#ffffff",
                fontSize: 18,
                fontFamily: "bold",
              }}
              to="/services"
            >
              Services
            </Link>

            <Link
              style={{
                textDecoration: "none",
                color: "#ffffff",
                fontSize: 18,
                fontFamily: "bold",
              }}
              to="/blog"
            >
              Blog
            </Link>
            <Link
              style={{
                textDecoration: "none",
                color: "#ffffff",
                fontSize: 18,
                fontFamily: "bold",
              }}
              to="/ourteam"
            >
              Team
            </Link>
            <Link
              style={{
                textDecoration: "none",
                color: "#ffffff",
                fontSize: 18,
                fontFamily: "bold",
              }}
              to="/talktous"
            >
              ContactUs
            </Link>
          </Grid>
          <Grid
            container
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <h2 style={{ color: "#04d2c8", fontFamily: "Work Sans" }}>
              CONTACT US
            </h2>
            <p
              style={{
                color: "#ffffff",
                fontSize: 18,
                fontFamily: "bold",
                fontWeight: 400,
              }}
            >
              Ravindra executive, plot no 161, road no 9, near cgr international
              school, Ayyappa society, mega hills, madhapur, telangana 500081.
            </p>
            <div>
              <a
                href="https://www.linkedin.com/in/kismiriya-in-02159a227/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon color="warning" sx={{ fontSize: 40 }} />
              </a>
              <a
                style={{ marginLeft: 20, marginRight: 20 }}
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon color="warning" sx={{ fontSize: 40 }} />
              </a>
              <a
                href="https://mail.google.com/mail/u/0/?tab=rm#inbox"
                target="_blank"
                rel="noreferrer"
              >
                <EmailIcon color="warning" sx={{ fontSize: 40 }} />
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CommonFooter;
