import React from "react";
import { Grid } from "@mui/material";
import MyDevelop from "../img/develop.jpg";
import Mydevice from "../img/device.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import CommonFooter from "./CommonFooter";
import myImage from "../img/anjani.jpg";
import MyGoal from "../img/rocket.jpg";
import Sumit from "../img/sumitk.jpeg";
import Nidhi from "../img/nidhi.jpeg";
import Vikash from "../img/vikash.jpeg";
import Surbhi from "../img/surbhi.jpeg";
import Boon from "../img/boon1.jpeg";

const About = () => {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#4e5d78",
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: "10em",
          paddingBottom: "5em",
        }}
      >
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img
              style={{ width: "100%", borderRadius: 10 }}
              src={MyDevelop}
              alt="develop"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#fafbfb",
                fontSize: "3.25em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Always Moving Forward
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#e1e4e8",
                fontSize: "1.3em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              We are a team that likes to tackle tough problems, hit walls, but
              always power through and keep moving forward.
            </p>
          </Grid>
        </Grid>
      </div>
      <div style={{ backgroundColor: "#fff", paddingTop: 50 }}>
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#000",
                fontSize: "3.25em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Many Years of Great Code
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#000",
                fontSize: "1.3em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                textAlign: "justify",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              We’re an accomplished nearshore software development firm that
              provides highly skilled, team-based resources to companies with
              strategic software needs. Since 2018, startups and enterprise
              companies have chosen us to drive new product growth and technical
              initiatives in a variety of industries.
            </p>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img
              style={{ width: "80%", borderRadius: 10 }}
              src={Mydevice}
              alt="my device"
            />
          </Grid>
        </Grid>
        <div>
          <h2
            style={{
              fontFamily: "DM Sans",
              color: "#596780",
              fontSize: "2.5em",
              lineHeight: "1.3em",
              fontWeight: "500",
              letterSpacing: "-1px",
              textTransform: "none",
            }}
          >
            Through it all, we relentlessly measure our progress, question our
            assumptions, and integrate new and better ideas.
          </h2>
        </div>
      </div>
      <div>
        <h1
          style={{
            fontFamily: "DM Mono",
            color: "#0a1f44",
            fontSize: "1.75em",
            lineHeight: "1.3em",
            fontWeight: "500",
            textAlign: "center",
            letterSpacing: "0px",
          }}
        >
          Leadership
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Card sx={{ maxWidth: 345, marginTop: 5 }}>
            <CardActionArea>
              <img
                style={{ height: 240, width: 300 }}
                src={Sumit}
                alt="sumit roy"
              />
              <CardContent>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    color: "#0a1f44",
                    fontSize: "1.25em",
                    lineHeight: "1em",
                    fontWeight: "700",
                    letterSpacing: "0px",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Sumit Kumar Roy
                </Typography>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    color: "#0a1f44",
                    fontSize: "1em",
                    lineHeight: "1.4em",
                    fontWeight: 400,
                    letterSpacing: "0px",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Software Engineer.
                </Typography>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    color: "#ff8c00",
                    fontSize: "1em",
                    lineHeight: "1em",
                    fontWeight: 700,
                    letterSpacing: "0px",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <a
                    style={{ textDecoration: "none", color: "#ff8c00" }}
                    href="https://www.linkedin.com/in/sumit-kumar-roy-875281b5/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Linkedin
                  </a>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: 5 }}>
            <CardActionArea>
              <img
                style={{ height: 240, width: 300 }}
                src={myImage}
                alt="anjani kumar"
              />
              <CardContent>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    color: "#0a1f44",
                    fontSize: "1.25em",
                    lineHeight: "1em",
                    fontWeight: "700",
                    letterSpacing: "0px",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Anjani Kumar
                </Typography>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    color: "#0a1f44",
                    fontSize: "1em",
                    lineHeight: "1.4em",
                    fontWeight: 400,
                    letterSpacing: "0px",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Software Engineer
                </Typography>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    color: "#ff8c00",
                    fontSize: "1em",
                    lineHeight: "1em",
                    fontWeight: 700,
                    letterSpacing: "0px",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <a
                    style={{ textDecoration: "none", color: "#ff8c00" }}
                    href="https://www.linkedin.com/in/anjani-kumar-458096141/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Linkedin
                  </a>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: 5 }}>
            <CardActionArea>
              <img
                style={{ height: 240, width: 300 }}
                src={Vikash}
                alt="vikash kumar"
              />
              <CardContent>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    color: "#0a1f44",
                    fontSize: "1.25em",
                    lineHeight: "1em",
                    fontWeight: "700",
                    letterSpacing: "0px",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Vikash Kumar
                </Typography>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    color: "#0a1f44",
                    fontSize: "1em",
                    lineHeight: "1.4em",
                    fontWeight: 400,
                    letterSpacing: "0px",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Python Developer
                </Typography>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    color: "#ff8c00",
                    fontSize: "1em",
                    lineHeight: "1em",
                    fontWeight: 700,
                    letterSpacing: "0px",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <a
                    style={{ textDecoration: "none", color: "#ff8c00" }}
                    href="https://www.linkedin.com/in/vkumaryy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Linkedin
                  </a>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <h1
          style={{
            fontFamily: "DM Mono",
            color: "#0a1f44",
            fontSize: "1.75em",
            lineHeight: "1.3em",
            fontWeight: "500",
            textAlign: "center",
            letterSpacing: "0px",
          }}
        >
          The Team
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Card sx={{ maxWidth: 345, marginTop: 5 }}>
            <CardActionArea style={{ height: 350 }}>
              <img
                style={{ height: 350, width: 300, borderRadius: 5 }}
                src={Nidhi}
                alt="anjani kumar"
              />
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: 5 }}>
            <CardActionArea style={{ height: 350 }}>
              <img
                style={{ height: 350, width: 300, borderRadius: 5 }}
                src={Boon}
                alt="boon"
              />
            </CardActionArea>
          </Card>

          <Card sx={{ maxWidth: 345, marginTop: 5 }}>
            <CardActionArea style={{ height: 350 }}>
              <img
                style={{ height: 350, width: 300, borderRadius: 5 }}
                src={Surbhi}
                alt="anjani kumar"
              />
            </CardActionArea>
          </Card>
        </div>
      </div>
      <div style={{ marginTop: 50 }}>
        <h1
          style={{
            fontFamily: "DM Sans",
            color: "#0a1f44",
            fontSize: "2.25em",
            lineHeight: "1em",
            fontWeight: 500,
            textAlign: "center",
            letterSpacing: "0px",
          }}
        >
          We code in a broad range of technologies
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: 50,
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              HTML5
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              CSS3
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              JavaScript
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              JQuery
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              AJAX
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              JSON
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              Bootstrap5
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              React
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              Angular
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              Python
            </h2>
          </div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#4589ff",
              borderRadius: "8em",
              textDecoration: "none",
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              marginTop: "1em",
              marginLeft: 5,
            }}
          >
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#002d9c",
                fontSize: "1em",
                lineHeight: "1.4em",
                fontWeight: "500",
                letterSpacing: "0px",
              }}
            >
              React Native
            </h2>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 50,
          backgroundColor: "#717d92",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img
              style={{ width: "70%", borderRadius: 10 }}
              src={MyGoal}
              alt="mygoal"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2
              style={{
                fontFamily: "DM Sans",
                color: "#000",
                fontSize: "3.25em",
                lineHeight: "1.15em",
                fontWeight: 500,
                letterSpacing: "-1px",
              }}
            >
              Our 2023 Goals
            </h2>
            <p
              style={{
                fontFamily: "DM Sans",
                color: "#000",
                fontSize: "1.1em",
                lineHeight: "1.5em",
                fontWeight: 400,
                letterSpacing: "0px",
                textAlign: "left",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              We believe in holding ourselves accountable year after year, so
              we’re excited to share our goals for this year.
            </p>
          </Grid>
        </Grid>
      </div>
      <CommonFooter />
    </div>
  );
};

export default About;
