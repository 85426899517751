import React from "react";
import MainCarousel from "./MainCarousel";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import TreningTech from "../img/teacher.jpg";
import Vikash from "../img/vikash.jpeg";
import Sumit from "../img/sumit_k.jpeg";
import MyTeam from "../img/team.jpg";
import Anjani from "../img/anjani.jpg";
import CommonFooter from "./CommonFooter";

const Home = () => {
  return (
    <div>
      <MainCarousel />
      <div
        style={{
          justifyContent: "center",
          marginTop: 30,
          backgroundColor: "rgb(255, 255, 255)",
        }}
      >
        <h2
          style={{
            fontSize: 25,
            color: "#04d2c8",
            fontFamily: "DM Sans",
            marginTop: 50,
          }}
        >
          ABOUT US
        </h2>
      </div>
      <div style={{ marginTop: "3em" }}>
        <Grid container style={{ padding: 30 }}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img
              style={{ width: "100%", borderRadius: 10 }}
              src={TreningTech}
              alt="trening tech"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2
              style={{
                fontSize: 20,
                color: "#04d2c8",
                fontFamily: "DM Sans",
              }}
            >
              OUR HISTORY
            </h2>
            <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  fontSize: 20,
                  lineHeight: "1.3em",
                  fontWeight: 400,
                  opacity: 0.7,
                  textAlign: "justify",
                }}
              >
                Kismiriya is the center point of great, cost-productive,
                dependable, and result-driven web and eCommerce answers for the
                customer base in a worldwide range. In giving you the most
                Experience, Professionalism, and Skill are the three fundamental
                things we depend on.
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  lineHeight: "1.3em",
                  fontWeight: 400,
                  fontSize: 20,
                  opacity: 0.7,
                  textAlign: "justify",
                }}
              >
                Expanded productivity and practical advantages (to your business
                and end clients) are two guaranteed results which you will
                appreciate with Kismiriya exceptional and imaginative web
                arrangements.
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  lineHeight: "1.3em",
                  fontWeight: 400,
                  fontSize: 20,
                  opacity: 0.7,
                  textAlign: "justify",
                  wordSpacing: "-0.2em",
                }}
              >
                Kismiriya is a software development company specializing in
                providing with custom technology solutions. As a mobile and
                web-based application development company with over skilled
                software developers, Kismiriya combines technological expertise,
                specific domain experience, and a passion for excellence to
                deliver enterprise-grade solutions.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          padding: "2em",
          backgroundColor: "#4e5d78",
          marginTop: "3em",
        }}
      >
        <h1 style={{ color: "#fff", fontFamily: "DM Sans" }}>
          Why Choose Kismiriya?
        </h1>
        <p
          style={{
            color: "#fff",
            fontWeight: "400",
            opacity: 0.6,
            fontSize: 20,
            lineHeight: 1.5,
            fontFamily: "DM Sans",
          }}
        >
          Kismiriya is a software development company specializing in providing
          with custom technology solutions. As a mobile and web-based
          application development company with over skilled software developers,
        </p>
      </div>
      <div style={{ backgroundColor: "#fff" }}>
        <Grid container style={{ padding: 30 }} spacing={1}>
          <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div style={{ padding: 5 }}>
              <img
                style={{ width: "100%", borderRadius: 10 }}
                src={MyTeam}
                alt="my team"
              />
            </div>
          </Grid>
          <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div style={{ padding: 5 }}>
              <h2 style={{ color: "#04d2c8", fontFamily: "DM Sans" }}>
                Software Development Services for Startups
              </h2>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  fontSize: 20,
                  lineHeight: "1.3em",
                  opacity: 0.7,
                  textAlign: "justify",
                  wordSpacing: "-0.1em",
                }}
              >
                At Kismiriya we are problem solvers above all and we love new
                challenges. Thus, we love working As a startups. Entrepreneurs
                have brilliant ideas
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  fontSize: 20,
                  lineHeight: "1.3em",
                  opacity: 0.7,
                  textAlign: "justify",
                  wordSpacing: "-0.1em",
                }}
              >
                Kismiriya has the necessary technical expertise and rigorous
                processes to help transform ideas into a service or a product
                and deliver it to the market. Kismiriya has worked as a startups
                at just about every stage of growth and funding.
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  fontSize: 20,
                  lineHeight: "1.3em",
                  opacity: 0.7,
                  textAlign: "justify",
                  wordSpacing: "-0.1em",
                }}
              >
                We have thorough understanding of critical milestones that must
                be accomplished to advance to the next stage of growth.
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  fontSize: 20,
                  lineHeight: "1.3em",
                  opacity: 0.7,
                  textAlign: "justify",
                  wordSpacing: "-0.1em",
                }}
              >
                Our commitment to contemporary Agile practices helps to tackle
                development goals in a time sensitive manner delivering market
                ready solutions to our clients.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ backgroundColor: "#fff" }}>
        <div style={{ padding: "3em" }}>
          <h1
            style={{
              color: "#04d2c8",
              fontSize: 25,
              textAlign: "center",
              fontFamily: "DM Sans",
            }}
          >
            Our Team
          </h1>
          <h2
            style={{
              color: "#212529",
              fontSize: 30,
              lineHeight: 1.2,
              fontFamily: "DM Sans",
            }}
          >
            People behind our success
          </h2>
          <p
            style={{
              color: "#212529",
              fontSize: 21,
              opacity: 0.6,
              fontFamily: "DM Sans",
            }}
          >
            We create our startups,and design great experiences to inspire our
            valuable clients. Our secret lies in forming a meaningful culture
            that brings us together as a dedicated team.
          </p>
        </div>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Card
            sx={{
              maxWidth: 300,
              marginTop: 5,
              boxShadow: "0 0 5em -1em black",
            }}
          >
            <CardActionArea>
              <img
                style={{ height: 200, width: 300 }}
                src={Anjani}
                alt="anjani"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Anjani Kumar
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Frontend Engineer with good experience in Web application,
                  mobile application development, and functional programming.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            sx={{
              maxWidth: 300,
              marginTop: 5,
              boxShadow: "0 0 5em -1em black",
            }}
          >
            <CardActionArea>
              <img
                style={{ height: 200, width: 300 }}
                src={Sumit}
                alt="sumit"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Sumit Kumar Roy
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  He has 3.5 years of experience in Web Development. He has
                  responsible for the coding, design and layout of a website
                  using Angular.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            sx={{
              maxWidth: 300,
              marginTop: 5,
              boxShadow: "0 0 5em -1em black",
            }}
          >
            <CardActionArea>
              <img
                style={{ height: 200, width: 300 }}
                src={Vikash}
                alt="vikash"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Vikash Kumar
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Worked as Python Developer and responsible for designing and
                  implementing solutions based on client requirements.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </div>
      <div style={{ marginTop: 30, backgroundColor: "#fff" }}>
        <Grid container style={{ padding: 30 }} spacing={1}>
          <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div style={{ padding: 5 }}>
              <h2
                style={{
                  color: "#04d2c8",
                  fontFamily: "DM Sans",
                  fontSize: 30,
                }}
              >
                Custom Software Development
              </h2>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  fontSize: 20,
                  lineHeight: "1.3em",
                  opacity: 0.7,
                  textAlign: "justify",
                  wordSpacing: "-0.1em",
                }}
              >
                Kismiriya is a premier custom software development and quality
                assurance company delivering complex world-class software
                solutions on a variety of technology.
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  fontSize: 20,
                  lineHeight: "1.3em",
                  opacity: 0.7,
                  textAlign: "justify",
                  wordSpacing: "-0.1em",
                }}
              >
                Custom software development dervices from dedicated developers
                with vast industry-specific experience custom web development
                implementation & deployment API
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  color: "rgb(0, 0, 0)",
                  fontSize: 20,
                  lineHeight: "1.3em",
                  opacity: 0.7,
                  textAlign: "justify",
                }}
              >
                Custom software development is a way to get a competitive
                advantage in the market due to the superior functionality a
                custom solution provides.
              </p>
            </div>
          </Grid>
          <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div style={{ padding: 5 }}>
              <img
                style={{ width: "100%", borderRadius: 10 }}
                src={MyTeam}
                alt="my team"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          backgroundColor: "#4e5d78",
          marginTop: "5em",
          paddingBottom: "5em",
        }}
      >
        <div style={{ padding: "5em" }}>
          <h2
            style={{
              color: "#04d2c8",
              fontSize: 20,
              textAlign: "center",
              fontFamily: "DM Sans",
            }}
          >
            TESTIMONIAL
          </h2>
          <h1
            style={{
              color: "#fff",
              fontSize: 30,
              textAlign: "center",
              fontFamily: "DM Sans",
            }}
          >
            Truth Be Told
          </h1>
          <p
            style={{
              color: "#fff",
              fontSize: 20,
              textAlign: "center",
              fontFamily: "DM Sans",
            }}
          >
            We are trusted by small to mid-sized organizations, Fortune
            companies, and major brands to advance their technology objectives
            through custom software solutions. Startup Institute is a career
            accelerator that allows professionals to learn new skills, take
            their careers in a different direction, and pursue a career they are
            passionate about.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Card sx={{ maxWidth: 300, marginTop: 5 }}>
            <CardActionArea>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ textAlign: "left" }}
                >
                  We thank Kismiriya for the wonderful job in helping us develop
                  our program. Everyone was professional, excellent and hard
                  working.
                </Typography>
                <Typography style={{ textAlign: "right" }}>Amar-</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 300, marginTop: 5 }}>
            <CardActionArea>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ textAlign: "left" }}
                >
                  Just wanted to let you know that our client is very happy. I
                  know that the deadlines were very strict. Thanks to your
                  managing capabilities, everything went smooth!
                </Typography>
                <Typography style={{ textAlign: "right" }}>Vikas-</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 300, marginTop: 5 }}>
            <CardActionArea>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ textAlign: "left" }}
                >
                  I have been involved in projects for major corporations and
                  the team Kismiriya is the best that I have worked with. I
                  would recommend them to anyone.
                </Typography>
                <Typography style={{ textAlign: "right" }}>Dhanjay-</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
};

export default Home;
