import React, { useState, useRef } from "react";
import MyBridge from "../img/contact.jpg";
import { Grid } from "@mui/material";
import CommonFooter from "./CommonFooter";
import Profile from "../img/profile.png";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <div>
      <p style={{ fontSize: "1em", color: "#76ff03", fontFamily: "DM Sans" }}>
        Your message has been successfully sent. I will contact you soon
      </p>
    </div>
  );
};

const TalkToUs = () => {
  const form = useRef();
  const [show, setShow] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_f1fsgeb",
        "template_aextxef",
        form.current,
        "user_ERqLAB9x6D0rXQFpHEM7b"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setShow(true);
  };

  setTimeout(() => {
    setShow(false);
  }, 5000);

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${MyBridge})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: 100,
          paddingTop: 100,
          height: "100vh",
          width: "100vw",
        }}
      >
        <div>
          <Grid container>
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
              <h2
                style={{
                  color: "#000000",
                  fontWeight: "bold",
                  fontSize: "3vh",
                }}
              >
                We are here to help you. If you have any question, Let us Know!
              </h2>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  height: "85vh",
                  margin: 20,
                  paddingTop: 30,
                  borderRadius: 10,
                  paddingBottom: 10,
                }}
              >
                <img alt="profile" src={Profile} />
                <h2
                  style={{
                    fontFamily: "DM Sans",
                    color: "#1de9b6",
                    fontSize: "1.25em",
                    lineHeight: "1.5em",
                    fontWeight: 500,
                    // letterSpacing: "-1px",
                  }}
                >
                  I am interested in discussing my ideas with you for
                </h2>
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ marginTop: "3vh" }}>
                    <input
                      type="text"
                      name="user_name"
                      placeholder="Name"
                      required
                      style={{
                        width: "60%",
                        height: "5vh",
                        outline: "none",
                        borderWidth: 1,
                        borderColor: "#2196f3",
                        borderRadius: 5,
                        fontSize: 15,
                        color: "#000000",
                        fontFamily: "DM Sans",
                        paddingLeft: 10,
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "3vh" }}>
                    <input
                      type="email"
                      name="user_email"
                      placeholder="Email"
                      required
                      style={{
                        width: "60%",
                        height: "5vh",
                        outline: "none",
                        borderWidth: 1,
                        borderColor: "#2196f3",
                        borderRadius: 5,
                        fontSize: 15,
                        color: "#000000",
                        fontFamily: "DM Sans",
                        paddingLeft: 10,
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "3vh" }}>
                    <input
                      type="text"
                      name="phone_number"
                      placeholder="Phone Number"
                      required
                      style={{
                        width: "60%",
                        height: "5vh",
                        outline: "none",
                        borderWidth: 1,
                        borderColor: "#2196f3",
                        borderRadius: 5,
                        fontSize: 15,
                        color: "#000000",
                        fontFamily: "DM Sans",
                        paddingLeft: 10,
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "3vh" }}>
                    <textarea
                      name="message"
                      placeholder="Message"
                      required
                      style={{
                        width: "60%",
                        height: "6vh",
                        outline: "none",
                        borderWidth: 1,
                        borderColor: "#2196f3",
                        borderRadius: 5,
                        fontSize: 15,
                        color: "#000000",
                        fontFamily: "DM Sans",
                        paddingLeft: 10,
                      }}
                    />
                    <div style={{ marginTop: "3vh" }}>
                      <input
                        type="submit"
                        value="Send"
                        style={{
                          width: "62%",
                          height: "5vh",
                          borderWidth: 1,
                          borderColor: "#2196f3",
                          borderRadius: 5,
                          backgroundColor: "#2196f3",
                          fontSize: 20,
                          color: "#ffffff",
                          fontFamily: "DM Sans",
                        }}
                      />
                    </div>
                  </div>
                </form>
                <div>{show ? <Result /> : null}</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
};

export default TalkToUs;
