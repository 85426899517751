import TopAppBar from "./components/TopAppBar";
import { Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Blog from "./components/Blog";
import Clients from "./components/Clients";
import OurTeam from "./components/OurTeam";
import Services from "./components/Services";
import TalkToUs from "./components/TalkToUs";
import "./App.css";

function App() {
  return (
    <main className="App">
      <TopAppBar />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={About} />
        <Route path="/blog" component={Blog} />
        <Route path="/clients" component={Clients} />
        <Route path="/ourteam" component={OurTeam} />
        <Route path="/services" component={Services} />
        <Route path="/talktous" component={TalkToUs} />
      </Switch>
    </main>
  );
}

export default App;
