import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import firstImage from "../img/building.jpg";
import secondImage from "../img/mobile.jpg";
import thirdImage from "../img/building1.jpg";
import fourthImage from "../img/innovation.jpg";
import "../styles/Carousel.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "We Are Passionate In Developing  Responsive Website.",
    imgPath: firstImage,
  },
  {
    label: "Accelerating Your  Digital Transformation And Business Journey",
    imgPath: secondImage,
  },
  {
    label: "Website That Work, For Organizations That Make A Difference",
    imgPath: thirdImage,
  },
  {
    label:
      " We provide expert business analysis, design, and development of mobile applications and provide further optimization and scale-up on demand.",
    imgPath: fourthImage,
  },
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
      <Typography className="mainContent">
        {images[activeStep].label}
      </Typography>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: "100vh",
                  display: "block",
                  maxWidth: "100%",
                  overflow: "hidden",
                  width: "100%",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </Box>
  );
}

export default SwipeableTextMobileStepper;
